import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "promise-polyfill/src/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import config from "./config";

const rootEl = document.getElementById("app-site");
const container = document.createElement("chat-app");
document.body.appendChild(container);
//ReactDOM.render(<App />, container);
ReactDOM.render(
  <App
    token={localStorage.getItem("chatbotsystem_user_token")}
    chatbotlang={window.chatbotlang}
  />,
  container
);
