import React, { Component } from "react";
import "./Badge.css";
import config from "./config";

class Badge extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatbotlang:
        !this.props.chatbotlang || typeof this.props.chatbotlang == "undefined"
          ? "en"
          : this.props.chatbotlang,
    };
  }
  async componentDidMount() {
    await this.setState({ bottom: -64 });
    while (this.state.bottom < 78) {
      await new Promise((resolve) => setTimeout(resolve, 1));
      await this.setState((state) => ({ bottom: state.bottom + 1 }));
    }
  }

  componentDidUpdate() {
    let oldlang = this.state.chatbotlang;
    let newlang =
      !window.chatbotlang || typeof window.chatbotlang == "undefined"
        ? "en"
        : window.chatbotlang;
    if (oldlang != newlang) {
      this.setState({ chatbotlang: newlang });
    }
  }

  render() {
    if (this.state.bottom === undefined) {
      return null;
    }
    return (
      <div
        className={
          this.state.chatbotlang == "ar"
            ? "infoSt-badge"
            : "infoSt-badge infoSt-badge-right"
        }
        style={{ bottom: `${this.state.bottom}px` }}
        onClick={this.props.onClick}
      >
        <img
          alt="Tahkeem Avatar"
          src={`${config.cssJsHostPublic}/chatbotinfost/webchat_images/Asset22.gif`}
          className="infoSt-envelope infoSt-envelope-top"
        />
      </div>
    );
  }
}

export default Badge;
