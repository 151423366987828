import React, { useState, Component, Fragment } from "react";
import ReactDOM from "react-dom";
import onClickOutside from "react-onclickoutside";
import io from "socket.io-client";
import { Manager } from "socket.io-client";
import config from "./config";
import { getFullTime, urlify } from "./strings";
import "./Chat.css";
import { Avatar } from "@material-ui/core";
import Slider from "./slider/Slider";
import Htmlparser from "html-react-parser";
import swal from "sweetalert2";
import axios from "axios";
import qs from "qs";
import StarRatingComponent from "react-star-rating-component";
import MomentUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  DateTimePicker,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import PropTypes from "prop-types";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@material-ui/icons/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@material-ui/icons/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@material-ui/icons/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import Rating from "@material-ui/lab/Rating";
import Box from "@material-ui/core/Box";
import { withStyles } from "@material-ui/core/styles";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

const getFileURL = (file) => `${config.cssJsHostPublic}/${file}`;

const StyledRating = withStyles({
  iconFilled: {
    color: "#FFB907",
  },
  iconHover: {
    color: "#FFB907",
  },
})(Rating);
const labels = {
  1: "لم تعجبني",
  2: "جيدة",
  3: "ممتازة",
  4: "جيدة جداً",
  5: "ممتازة",
};
const labels_en = {
  1: "Dislike",
  2: "Good",
  3: "Like",
  4: "Very Good",
  5: "Like",
};

const customIcons = {
  1: {
    icon: <SentimentDissatisfiedIcon style={{ fontSize: 75, color: "red" }} />,
    label: "لم تعجبني",
  },
  2: {
    icon: <SentimentSatisfiedIcon style={{ fontSize: 75, color: "#FFB326" }} />,
    label: "مقبولة",
  },

  3: {
    icon: (
      <SentimentVerySatisfiedIcon style={{ fontSize: 75, color: "#12CA18" }} />
    ),
    label: "ممتازة",
  },
};

const customIcons_en = {
  1: {
    icon: <SentimentDissatisfiedIcon style={{ fontSize: 75, color: "red" }} />,
    label: "Dislike",
  },
  2: {
    icon: <SentimentSatisfiedIcon style={{ fontSize: 75, color: "#FFB326" }} />,
    label: "Good",
  },

  3: {
    icon: (
      <SentimentVerySatisfiedIcon style={{ fontSize: 75, color: "#12CA18" }} />
    ),
    label: "Like",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return (
    <span {...other}>
      {props.chat_lang == "ar"
        ? customIcons[value].icon
        : customIcons_en[value].icon}
    </span>
  );
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

window.io = io;

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      window.localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return window.localStorage.getItem(key);
    });
  },
};

class Chat extends Component {
  state = {};

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      closing: false,
      record: false,
      cancelRecord: false,
      loading: false,
      loadingMessages: false,
      userId: null,
      chatId: null,
      inputValue: "",
      messages: [],
      fileUploadState: "",
      fileUpload: "",
      file: null,
      displayButtons: true,
      selectedItemFromListRadio: "",
      selectedItemTextFromListRadio: "",
      changeDate: new Date(),
      maxDate: null,
      minDate: null,
      chat_lang: this.props.lang ? this.props.lang : "en",
      token: this.props.token ? this.props.token : "",
      chatbotlang:
        !this.props.chatbotlang || typeof this.props.chatbotlang == "undefined"
          ? "en"
          : this.props.chatbotlang,
      rating: 2,
      ratinghover: -1,
      Radio: "",
      hideInfoStInput: false,
      isLastMessagePassword: false,
    };
    this.getBase64 = this.getBase64.bind(this);
    this.sendButtonAnswer = this.sendButtonAnswer.bind(this);
    this.onStop = this.onStop.bind(this);
    this.download = this.download.bind(this);
    this.takeASnap = this.takeASnap.bind(this);
  }
  socket = null;

  generateKey = () => {
    var S4 = function () {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    let key = S4();
    return `key_${new Date().getTime()}` + key;
  };

  hideMessage = (predicate, message = null) => {
    let messages = this.state.messages;
    for (let i = 0; i < messages.length; i++) {
      if (predicate(messages[i])) {
        if (message) {
          message.time = messages[i].time;
          message.file_waiting = false;
          messages[i] = message;
        } else {
          if (!messages[i].file_waiting) messages[i].display = false;
          messages[i].file_waiting = false;
        }
        break;
      }
    }

    this.setState({ messages });
  };

  async onStarClick(nextValue, isFinalRating = false) {
    await this.setState({ rating: nextValue, closing: false });
    let externaltoken = await asyncLocalStorage.getItem(
      "chatbotsystem_user_token"
    );
    if (this.socket)
      this.socket.emit("send_message", {
        type: "plain_text",
        content: isFinalRating
          ? "ratefn-" + this.state.rating
          : "rating-" + this.state.rating,
        channel: 1,
        action: null,
        lang: this.state.chatbotlang,
        hand_writing: false,
        token: externaltoken,
      });
  }

  async onStarClickClosing(newValue) {
    await this.onStarClick(newValue, true);
  }

  async onStarHover(newHover, prevValue, name) {
    await this.setState({ ratinghover: newHover });
  }

  async onStarHoverOut(nextValue, prevValue, name) {
    await this.setState({ rating: 0 });
  }

  startRecording = () => {
    this.setState({ record: true });
    let timerInterval;
    swal
      .fire({
        target: document.getElementById("infoSt-content"),
        allowOutsideClick: false,
        allowEscapeKey: false,
        title:
          this.state.chatbotlang == "ar"
            ? "عند الانتهاء اضغط على إرسال"
            : "Please press Send Button when finish recording",
        confirmButtonText: this.state.chatbotlang == "ar" ? "إرسال" : "Send",
        cancelButtonText: this.state.chatbotlang == "ar" ? "إلغاء" : "Cancel",
        showCancelButton: true,
        showConfirmButton: true,
        customClass: {
          popup: "swal-popup-custom",
          confirmButton: "swal-button-custom",
          cancelButton: "swal-button-custom",
        },
        padding: 0,
        confirmButtonColor: "#C6AA78",
        cancelButtonColor: "#C6AA78",
        imageUrl: getFileURL("chatbotinfost/webchat_images/mico.png"),
        imageWidth: 100,
        imageHeight: 100,
        imageAlt: "Custom image",

        html:
          this.state.chatbotlang == "ar"
            ? " باقي <b></b>  ثانية لإنهاء التسجيل"
            : "There are <b></b> Seconds to finish recording ",

        timer: 12000,
        timerProgressBar: true,
        onOpen: () => {
          timerInterval = setInterval(() => {
            const content = swal.getContent();
            if (content) {
              const b = content.querySelector("b");
              if (b) {
                b.textContent = Math.round(swal.getTimerLeft() / 1000);
              }
            }
          }, 1000);
        },
        onClose: () => {
          clearInterval(timerInterval);
        },
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          this.stopRecording();
        } else if (result.dismiss === swal.DismissReason.cancel) {
          this.cancelRecording();
        } else if (result.dismiss === swal.DismissReason.timer) {
          this.stopRecording();
        }
      });
  };

  stopRecording = () => {
    this.setState({ record: false });
  };

  cancelRecording = () => {
    this.setState({ cancelRecord: true, record: false });
  };

  onData(recordedBlob) {}

  async onStop(recordedBlob) {
    if (!this.state.cancelRecord) {
      const scope = this;
      console.info("recordedBlob is: ", recordedBlob);
      var result = {};
      var reader = new FileReader();
      reader.readAsDataURL(recordedBlob.blob);
      reader.onloadend = async () => {
        var base64data = reader.result;
        base64data = base64data.split(";base64,")[1];
        let base64text = qs.stringify({ text: base64data });

        result = await axios.post(
          `${config.network.socketHostPublic}/compress/v1/stt`,
          base64text,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        await scope.setState({ inputValue: result.data.result });
        scope.onSend();
      };
    } else this.setState({ cancelRecord: false });
  }

  gettoken = () => {
    return fetch(`${config.network.socketHostPublic}/gettoken`)
      .then((res) => {
        return res.json();
      })
      .catch(() => ({}));
  };

  askForCameraSnapShot() {
    if (navigator.mediaDevices === undefined) {
      navigator.mediaDevices = {};
    }
    if (navigator.mediaDevices.getUserMedia === undefined) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia =
          navigator.webkitGetUserMedia || navigator.mozGetUserMedia;

        if (!getUserMedia) {
          return Promise.reject(
            new Error("getUserMedia is not implemented in this browser")
          );
        }

        return new Promise(function (resolve, reject) {
          getUserMedia.call(navigator, constraints, resolve, reject);
        });
      };
    }
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((localStream) => {
        this.setState({ LocalStream: localStream });
        var video = document.getElementById("video-player");
        video.srcObject = new MediaStream(localStream);
        video.play();
      })
      .then(() => {
        const btn = document.getElementById("snapshot");
        btn.disabled = false;
        btn.onclick = (e) => {
          this.takeASnap().then(this.download);
        };
      })
      .catch((e) => {});
  }

  takeASnap() {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    var vid = document.getElementById("video-player");

    canvas.width = vid.videoWidth;
    canvas.height = vid.videoHeight;

    ctx.drawImage(vid, 0, 0);
    return new Promise((res, rej) => {
      canvas.toBlob(res, "image/jpeg");
    });
  }
  download(blob) {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = `screenshot${new Date().getTime()}.jpg`;
    document.body.appendChild(a);
    a.click();
    this.setState({ enableTakeSnap: false });
    this.hideMessage((obj) => obj.temp_message === "camera");
    this.getBase64(null, blob, a.download);
  }

  useCamera() {
    const use_camera_message = {
      action: null,
      chat_id: 0,
      content: {
        message:
          this.state.chat_lang == "ar"
            ? "التقط وارسل الصورة من خلال الكاميرا "
            : "Take a photo and send using camera",
        buttons: [
          {
            title:
              this.state.chat_lang == "ar" ? "القائمة الرئيسية" : "Main Menu",
            action: "مرحبا",
          },
        ],
      },
      lang: this.state.chat_lang,
      sequence: 23,
      time: new Date(),
      type: "camera",
      user_id: 1,
      display: true,
      temp_message: "camera",
    };

    let messages = this.state.messages;
    messages = [use_camera_message, ...messages];

    this.setState({ messages, enableTakeSnap: true });
    this.askForCameraSnapShot();
  }

  async componentDidMount() {
    if (this.newMessage) this.newMessage.focus();

    this.setState({
      cameraSupported: !(typeof navigator.mediaDevices == "undefined"),
    });

    let token = window.localStorage.getItem("chat_token");
    let chat_lang = window.localStorage.getItem("chat_lang");
    let chatbotlang = window.localStorage.getItem("chatbotlang");
    chat_lang =
      chat_lang && chat_lang != null && typeof chat_lang != "undefined"
        ? chat_lang
        : "en";

    this.setState({
      loading: true,
      chat_lang,
    });

    let data = { token: token };
    if (!(token && token != "null" && token != "undefined")) {
      data = await this.gettoken();
    }
    token = data.token;
    window.localStorage.setItem("chat_token", token);
    window.localStorage.setItem("chat_lang", chat_lang);
    // this.socket = io.connect(`${config.network.socketHostPublic}/user`, {
    //   // path: "/socket.io",
    //   // path: "/ChatbotAdminPanel/user/socket.io",
    //   path: "/ChatbotAdminPanel/socket.io",
    //   reconnect: true,
    //   rejectUnauthorized: false,
    //   query: { token },
    // });

    const manager = new Manager(config.network.socketHostPublic, {
      // path: "/ChatbotAdminPanel/socket.io",
      reconnect: false,
      rejectUnauthorized: false,
      query: { token },
    });
    this.socket = manager.socket("/user");

    if (this.socket) {
      this.socket.on("connect", () => {
        this.setState({
          error: null,
        });
      });
      this.socket.on("connect_error", () => {
        this.setState({ error: "Network error" });
      });
      this.socket.on("userCount", (data) => {
        alert(data.message);
      });
      this.socket.on("connect_timeout", () => {
        this.setState({ error: "Network error" });
      });
      this.socket.on("reconnect", () => {
        this.setState({ error: null });
      });
      this.socket.on("reconnect_error", () => {
        this.setState({ error: "Network error" });
      });
      this.socket.on("reconnect_failed", () => {
        this.setState({ error: "Network error" });
      });
      this.socket.on("reconnect_attempt", () => {
        this.setState({ loading: true });
      });
      this.socket.on("reconnecting", () => this.setState({ loading: true }));

      this.socket.on("chat_error", ({ code, error, err_code }) => {
        window.localStorage.clear();
        this.props.onClose();
      });
      this.socket.on("chat_ready", ({ userId, chatId, session_id }) => {
        this.setState({
          loading: false,
          loadingMessages: true,
          userId,
          chatId,
          messages: [],
        });
        if (this.socket)
          this.socket.emit("chat_messages", {
            chatId,
            session_id,
            limit: 1000,
          });
      });

      this.socket.on("get_socketId", (socketid) => {});

      this.socket.on("chat_messages", async (data) => {
        let externaltoken = await asyncLocalStorage.getItem(
          "chatbotsystem_user_token"
        );
        if (this.socket)
          if (this.props.interval == 1) {
            //language is changed

            this.enforceNewSession();
          } else {
            this.socket.emit("send_message", {
              type: "plain_text",
              content: "control-hello",
              channel: 1,
              action: null,
              lang: this.state.chatbotlang,
              hand_writing: false,
              token: externaltoken,
            });
            this.setState({ messages: data.messages });
          }
        this.setState({ loadingMessages: false });
      });

      this.socket.on("new_message", async ({ message }) => {
        try {
          this.setState({
            questionTextDisabled: message.content.questionTextDisabled || false,
          });
        } catch {}

        await this.hideMessage((obj) => obj.temp_message === "camera");
        if (this.state.LocalStream)
          this.state.LocalStream.getTracks().forEach((track) => track.stop());

        if (this.newMessage) this.newMessage.focus();
        message.display = true;

        //message.type="webview"
        //Object.assign(message.content,content3)

        if (message.fileName) {
          this.hideMessage(
            (obj) => obj.temp_message === message.fileName,
            message
          );
        } else {
          this.setState({
            chat_lang: message.lang,
          });
          window.localStorage.setItem("chat_lang", message.lang);

          if (message.type == "list") {
            this.setState({
              selectedItemFromListRadio: message.content.items
                ? message.content.items[0].action
                : "",
              selectedItemTextFromListRadio: message.content.items
                ? message.content.items[0].title
                : "",
            });
          } else if (message.type == "radio") {
            this.setState({
              selectedItemFromListRadio: message.content.radios
                ? message.content.radios[0].action
                : "",
              selectedItemTextFromListRadio: message.content.radios
                ? message.content.radios[0].title
                : "",
            });
          }

          let finalMessages = await this.state.messages;

          const { description, photoUrl } = getIntroMessageData(
            this.state.chat_lang
          );

          if (this.state.messages.length == 0) {
            let welcome_message = {
              action: null,
              chat_id: 0,
              content: {
                cards: [
                  {
                    title: "",
                    description: description,
                    photoUrl: getFileURL(
                      "chatbotinfost/webchat_images/" + photoUrl
                    ),
                    buttons: [],
                  },
                ],
              },
              lang: this.state.chatbotlang,
              sequence: -1, //welcome message
              time: new Date(),
              type: "cards",
              user_id: 1,
              display: true,
              temp_message: "",
            };
            finalMessages = [welcome_message, ...finalMessages];
            /* 
            this.setState(() => ({messages: finalMessages,displayButtons: message.user_id == 1 ? true : false,}))
            setTimeout(null, 1000); */
          }

          finalMessages = [message, ...finalMessages];
          if (message.sender && message.sender == "user") {
            let waiting_obj = {
              action: null,
              chat_id: 0,
              content: getFileURL("chatbotinfost/webchat_images/dots2.gif"),
              lang: this.state.chat_lang,
              sequence: 22,
              time: new Date(),
              type: ".gif",
              user_id: 1,
              display: true,
              temp_message: "waiting_response",
            };
            finalMessages = [waiting_obj, ...finalMessages];
          }

          this.setState((state) => ({
            messages: finalMessages,
            displayButtons: message.user_id == 1 ? true : false,
            changeDate:
              message.type == "datetime"
                ? message.content.defaultValue
                  ? new Date(message.content.defaultValue)
                  : new Date()
                : new Date(),
            maxDate:
              message.type == "datetime"
                ? message.content.maxDate
                  ? new Date(message.content.maxDate)
                  : null
                : null,
            minDate:
              message.type == "datetime"
                ? message.content.minDate
                  ? new Date(message.content.minDate)
                  : null
                : null,

            Radio:
              message.type == "radio"
                ? message.content.radios
                  ? message.content.radios[0].action
                  : ""
                : "",
          }));

          if (message.user_id == 1)
            this.hideMessage((obj) => obj.temp_message === "waiting_response");
        }
      });
      this.socket.on("end_upload", ({ message, uuid }) => {
        if (uuid !== this.state.userId) {
        }
      });

      this.socket.on("upload_error", ({ message, uuid }) => {
        if (uuid !== this.state.userId) {
          this.hideMessage((obj) => obj.temp_message === message.fileName);
        }
      });
    }

    this.timer = setInterval(() => {
      for (let i = 0; i < this.state.messages.length; i++) {
        let message = this.state.messages[i];
        if (message.file_waiting) {
          let startDate = new Date(message.time);
          let endDate = new Date();
          let seconds = (endDate.getTime() - startDate.getTime()) / 1000;

          if (seconds > 60) {
            this.hideMessage(
              (obj) => obj.temp_message === message.temp_message
            );
          }
        }
      }
    }, 20000);
  }
  componentDidUpdate() {
    this.doUpdateLanguage();
    if (
      this.state.isLastMessagePassword &&
      this.state.messages[0] &&
      this.state.messages[0].type !== "password_req"
    )
      this.setState({
        isLastMessagePassword: false,
      });
    else if (
      !this.state.isLastMessagePassword &&
      this.state.messages[0] &&
      this.state.messages[0].type == "password_req"
    )
      this.setState({
        isLastMessagePassword: true,
      });
    this.myRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  doUpdateLanguage() {
    let oldlang = this.state.chatbotlang;
    let newlang =
      !window.chatbotlang || typeof window.chatbotlang == "undefined"
        ? "en"
        : window.chatbotlang;
    if (oldlang != newlang) {
      this.setState({ chatbotlang: newlang });
      this.enforceNewSession();
    }
  }

  enforceNewSession() {
    this.props.resetInterval();
    if (this.socket)
      this.socket.emit("send_message", {
        type: "plain_text",
        content: "enforceNewSession",
        channel: 1,
        action: null,
        lang: this.state.chatbotlang,
        hand_writing: false,
        token: null,
      });
  }

  activateBot = ({ chatId }) => {
    if (this.socket) this.socket.emit("chat_activate_bot", { chatId });
  };
  sendExitWindow = async () => {
    let externaltoken = await asyncLocalStorage.getItem(
      "chatbotsystem_user_token"
    );
    if (this.socket)
      this.socket.emit("send_message", {
        type: "control-exitWindow",
        content: "control-exitWindow",
        channel: 1,
        action: null,
        lang: this.state.chatbotlang,
        hand_writing: false,
        token: externaltoken,
      });
  };
  sendMinimizetWindow = async () => {
    this.stopRecording();
    this.setState({ messages: [] });
    this.props.onMinimize();
  };

  onSend = async () => {
    let externaltoken = await asyncLocalStorage.getItem(
      "chatbotsystem_user_token"
    );

    if (this.socket)
      this.socket.emit("send_message", {
        type: "plain_text",
        content: this.state.Radio
          ? this.state.selectedItemTextFromListRadio +
            "/" +
            this.state.inputValue
          : this.state.inputValue,
        channel: 1,
        action:
          this.state.Radio != ""
            ? this.state.Radio + "##" + this.state.inputValue
            : null,
        lang: this.state.chatbotlang,
        hand_writing: true,
        token: externaltoken,
      });
    this.setState({
      inputValue: "",
      closing: false,
      Radio: this.state.Radio == "0" ? "" : this.state.Radio,
    });
  };

  selectCategory = (e) => {
    let titleAndAction = e.target.value.split("%%%%");
    try {
      this.setState({
        selectedItemTextFromListRadio: titleAndAction[0],
        selectedItemFromListRadio: titleAndAction[1],

        Radio: e.target.type == "radio" ? titleAndAction[1] : "",
      });
    } catch (e) {
      this.setState({
        selectedItemTextFromListRadio: "",
        selectedItemFromListRadio: "",
        Radio: "",
      });
    }
  };

  handleClickOutside = () => {
    this.props.onClose();
  };

  getBase64 = (e, File = null, FileName = null) => {
    if (File) {
      File.lastModifiedDate = new Date();
      File.name = FileName;
    }
    var file = !File ? e.target.files[0] : File;
    const fileExt = file.name.split(".").pop();
    const acceptedFile = [
      "jpg",
      "png",
      "pdf",
      "doc",
      "gif",
      "JPG",
      "PNG",
      "PDF",
      "DOC",
      "GIF",
    ].includes(fileExt);

    if (acceptedFile) {
      if (!File) e.target.value = null;
      if (file.size <= config.maxFileSize) {
        let fileReader = new FileReader();
        let slice = file.slice(0, config.maxFileSize);
        fileReader.readAsArrayBuffer(slice);
        fileReader.onload = () => {
          var arrayBuffer = fileReader.result;
          if (this.socket)
            this.socket.emit("slice_upload", {
              userId: this.state.userId,
              chatId: this.state.chatId,
              channel: 1,
              name: file.name,
              type: file.type,
              size: file.size,
              data: arrayBuffer,
              lang: this.state.chatbotlang,
              hand_writing: false,
            });

          let upload_message = {
            action: null,
            chat_id: this.state.chatId,
            content: getFileURL(
              "chatbotinfost/webchat_images/Bubble-Preloader-1-1.gif"
            ),
            lang: this.state.chatbotlang,
            sequence: 22,
            time: new Date(),
            type: ".gif",
            user_id: this.state.userId,
            display: true,
            file_waiting: true,
            temp_message: file.name,
          };

          this.setState((state) => ({
            messages: [upload_message, ...state.messages],
          }));

          this.socket.on("request_slice_upload", (data) => {
            var place = data.currentSlice * config.maxFileSize;
            slice = file.slice(
              place,
              place + Math.min(config.maxFileSize, file.size - place)
            );

            fileReader.readAsArrayBuffer(slice);
          });
        };
        fileReader.onerror = function (error) {
          if (!File) e.target.value = null;
        };
      }
    } else {
      const not_acceptable_file_type_message = {
        action: null,
        chat_id: 0,
        content: {
          message:
            this.state.chat_lang == "ar"
              ? "نوع الملف المراد رفعه غير مقبول "
              : "File type is not accepted",
          buttons: [
            {
              title:
                this.state.chat_lang == "ar" ? "القائمة الرئيسية" : "Main Menu",
              action: "مرحبا",
            },
          ],
        },
        lang: this.state.chatbotlang,
        sequence: 22,
        time: new Date(),
        type: "question",
        user_id: 1,
        display: true,
        temp_message: "",
      };

      let messages = this.state.messages;
      messages = [not_acceptable_file_type_message, ...messages];

      this.setState({ messages });
    }
  };

  onChange = (e) => {
    this.setState({ inputValue: e.target.value });
  };

  onKeyDown = (e) => {
    if (e.key === "Enter") {
      this.onSend();
    }
  };

  render_Buttons = (buttons, index, func = 1) =>
    buttons
      ? buttons.map((object, index) => {
          return (
            <button
              /*key={this.generateKey()}*/
              title={object.title}
              value={object.action}
              className={object.classes + " infoSt-buttons infoSt-butt "}
              parent_div={`${index + "_btn"}`}
              onClick={(e) => {
                if (func) {
                  if (object.URL) window.open(object.URL, "_blank");
                  this.sendButtonAnswer(e);
                } else this.sendButtonAnswerofCards(object);
              }}
              style={this.getStyleFromString(object.style)}
            >
              {object.title}
            </button>
          );
        })
      : "";

  renderSpinner = (...text) => (
    <div
      className={
        this.state.messages.length <= 2 && this.state.messages.length != 0
          ? "infoSt-content-onetime  infoSt-no-messages-content"
          : "infoSt-content infoSt-no-messages-content"
      }
      style={{ textAlign: "center" }}
    >
      <div className="webchat-loader" />
      <div>
        {text.map((el, index) => (
          <Fragment>
            {el}
            <br />
          </Fragment>
        ))}
      </div>
    </div>
  );
  renderNetworkError = (...text) => (
    <div
      className={"infoSt-content-err infoSt-no-messages-content"}
      style={{ textAlign: "center" }}
    >
      <div className="webchat-loader" />
      <div>
        <Fragment>
          {
            <img
              src={getFileURL(
                "chatbotinfost/webchat_images/connection_error.gif"
              )}
              className="infoSt-img-err"
            />
          }
          <br />
          <span className="infoSt-span">
            {this.state.chatbotlang == "ar"
              ? "حدث خطأ في الاتصال"
              : "Error in connection"}{" "}
          </span>
        </Fragment>
        <Fragment>
          <br />
          <span className="infoSt-span">
            {this.state.chatbotlang == "ar"
              ? "جاري محاولة إعادة الاتصال"
              : "Attempting to reconnect chat server ...."}
          </span>
        </Fragment>
      </div>
    </div>
  );

  renderNoContent = () => (
    <div
      className={
        this.state.messages.length <= 2 && this.state.messages.length != 0
          ? "infoSt-content-onetime  infoSt-no-messages-content"
          : "infoSt-content infoSt-no-messages-content"
      }
    >
      <div className="infoSt-no-messages">
        <p className="infoSt-p">
          {this.state.chatbotlang == "ar"
            ? "... أهلاً وسهلاً بك"
            : "You are welcome ..."}{" "}
        </p>
      </div>
    </div>
  );

  sendButtonAnswerofCards(object) {
    if (object.URL) window.open(object.URL, "_blank");

    if (object.action)
      if (this.socket)
        this.socket.emit("send_message", {
          type: "plain_text",
          content: object.realTitle ? object.realTitle : object.title,
          channel: 1,
          action: object.action,
          lang: this.state.chatbotlang,
          hand_writing: false,
        });
  }
  getMatrix(elements) {
    let final3dMatrix = [];
    let matrxIdx = -1;
    let finalArr = [];

    for (var i = 0, k = -1; i < elements.length; i++) {
      if (i % 3 === 0) {
        k++;
        finalArr[k] = [];
      }

      finalArr[k].push(elements[i]);
    }

    finalArr.map((arr, arrIdx) => {
      if (arrIdx % 3 === 0) {
        matrxIdx++;
        final3dMatrix[matrxIdx] = [];
      }
      final3dMatrix[matrxIdx][arrIdx % 3] = arr;
    });
    return final3dMatrix;
  }

  async sendButtonAnswer(e) {
    e.persist();
    let externaltoken = await asyncLocalStorage.getItem(
      "chatbotsystem_user_token"
    );

    if (e.target.value) {
      if (this.socket)
        this.socket.emit("send_message", {
          type: "plain_text",
          content: e.target.realTitle ? e.target.realTitle : e.target.title,
          channel: 1,
          action: e.target.value,
          lang: this.state.chatbotlang,
          hand_writing: false,
          token: externaltoken,
        });
      this.setState({ displayButtons: false });
    }
  }

  renderMessages = () => (
    <div
      className={
        this.state.messages.length <= 2 && this.state.messages.length != 0
          ? "infoSt-content-onetime1"
          : "infoSt-content1"
      }
    >
      {this.state.messages.length === 0 ? (
        <div className="infoSt-no-messages">
          {this.state.chatbotlang == "ar"
            ? "... أهلاً وسهلاً بك"
            : "You are welcome ..."}
        </div>
      ) : (
        this.state.messages.map(
          (
            {
              content,
              time,
              user_id,
              type,
              lang,
              temp_message,
              display,
              sequence,
            },
            index
          ) =>
            display == null ||
            display == true ||
            typeof display == "undefined" ? (
              user_id != 1 ? (
                !temp_message ? (
                  <div>
                    <div
                      className={
                        this.state.userId === user_id
                          ? "infoSt-chat-user"
                          : "infoSt-chat-consultant"
                      }
                    >
                      {this.state.userId === user_id ? (
                        <Avatar className="infoSt-avatar">
                          <img
                            src={getFileURL(
                              "chatbotinfost/webchat_images/user.png"
                            )}
                            className="infoSt-avatar-bot"
                          />
                        </Avatar>
                      ) : (
                        <Avatar className="infoSt-avatar">
                          <i className="fas fa-users-cog"></i>
                        </Avatar>
                      )}

                      <div
                        key={index}
                        className={`${
                          type == ".doc" || type == ".pdf" || type == "file"
                            ? "infoSt-message-icon"
                            : "infoSt-message"
                        }
            ${
              this.state.userId === user_id
                ? lang == "en"
                  ? "infoSt-ltr-my-message"
                  : "infoSt-my-message"
                : "infoSt-consultant-message"
            }`}
                      >
                        {type == ".png" ||
                        type == ".gif" ||
                        type == ".jpg" ||
                        type == "image" ? (
                          <a href={content} target="_blank">
                            <img src={content}></img>
                          </a>
                        ) : type == ".doc" ||
                          type == ".pdf" ||
                          type == "file" ? (
                          <a href={content} target="_blank">
                            <img
                              src={
                                config.network.socketHostPublic +
                                "/webchat_images/pdffile.png"
                              }
                              width="50px"
                              height="50px"
                            ></img>
                          </a>
                        ) : (
                          <div
                            className={
                              lang == "en"
                                ? "infoSt-just-line-break-ltr"
                                : "infoSt-just-line-break"
                            }
                          >
                            {!content || content == ""
                              ? "..."
                              : this.state.messages[index + 1] &&
                                this.state.messages[index + 1].type ===
                                  "password_req"
                              ? "* * * * * * *"
                              : content}
                          </div>
                        )}
                        <p className="infoSt-message-time infoSt-p">
                          {getFullTime(time)}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={"infoSt-chat-user"}>
                      {
                        <Avatar className="infoSt-avatar">
                          <img
                            src={getFileURL(
                              "chatbotinfost/webchat_images/user.png"
                            )}
                            className="infoSt-avatar-bot"
                          />
                        </Avatar>
                      }
                      <div
                        key={index}
                        className={
                          "infoSt-message infoSt-upload-loader-message"
                        }
                      >
                        <p className="infoSt-p">
                          {" "}
                          {<img src={content}></img>}
                          <h5
                            className="infoSt-h5"
                            style={{ textAlign: "center" }}
                          >
                            {this.state.chat_lang == "ar"
                              ? "جاري رفع الملف ..."
                              : "File is uploading ..."}
                          </h5>
                        </p>
                      </div>
                    </div>
                  </div>
                )
              ) : temp_message == "waiting_response" ? (
                <div>
                  <div className={"infoSt-chat-bot"}>
                    {
                      <Avatar className="infoSt-avatar">
                        <img
                          src={getFileURL(
                            "chatbotinfost/webchat_images/chatLogo.png"
                          )}
                          className="infoSt-avatar-bot"
                        />
                      </Avatar>
                    }
                    <div className={" infoSt-wait-loader-message"}>
                      <p className="infoSt-p"> {<img src={content}></img>}</p>
                    </div>
                  </div>
                </div>
              ) : type == "question" ? (
                <div /*key={this.generateKey()}*/>
                  <div className={"infoSt-chat-bot"}>
                    <Avatar className="infoSt-avatar infoSt-avatarbot">
                      <img
                        src={getFileURL(
                          "chatbotinfost/webchat_images/chatLogo.png"
                        )}
                        className="infoSt-avatar-bot"
                      />
                    </Avatar>

                    <div
                      key={index}
                      className={
                        lang == "en"
                          ? "infoSt-ltr-bot-message" + " infoSt-message-ltr"
                          : "infoSt-bot-message" + " infoSt-message"
                      }
                    >
                      <div
                        className={
                          lang == "en"
                            ? "infoSt-just-line-break-ltr"
                            : "infoSt-just-line-break"
                        }
                      >
                        <p className="infoSt-p">
                          {content.message
                            ? Htmlparser(urlify(content.message, lang))
                            : Htmlparser(urlify(content, lang))}
                        </p>
                      </div>

                      <p className="infoSt-message-time infoSt-p">
                        {getFullTime(time)}
                      </p>
                    </div>
                  </div>
                  {content.message ? (
                    <div
                      className="infoSt-dis"
                      key={`${index + "_btn"}`}
                      style={{
                        display:
                          `${index + "_btn"}` === "0_btn" &&
                          this.state.displayButtons
                            ? "flex"
                            : "none",
                      }}
                    >
                      {this.state.cameraSupported && content.camera ? (
                        <button
                          className="infoSt-butlist infoSt-butt"
                          parent_div={`${index + "_btn"}`}
                          onClick={(e) => {
                            this.useCamera();
                          }}
                        >
                          {lang == "en" ? "Use Camera" : "استخدم الكاميرا"}
                        </button>
                      ) : (
                        ""
                      )}
                      {this.render_Buttons(content.buttons, index)}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : type == "datetime" ? (
                <>
                  <div className={"infoSt-chat-bot"}>
                    <Avatar className="infoSt-avatar infoSt-avatarbot">
                      <img
                        src={getFileURL(
                          "chatbotinfost/webchat_images/chatLogo.png"
                        )}
                        className="infoSt-avatar-bot"
                      />
                    </Avatar>

                    <div
                      key={index}
                      className={
                        lang == "en"
                          ? "infoSt-ltr-bot-message" + " infoSt-message-ltr"
                          : "infoSt-bot-message" + " infoSt-message"
                      }
                    >
                      <div
                        className={
                          lang == "en"
                            ? "infoSt-just-line-break-ltr"
                            : "infoSt-just-line-break"
                        }
                      >
                        <p className="infoSt-p">
                          {content.message
                            ? Htmlparser(urlify(content.message, lang))
                            : Htmlparser(urlify(content, lang))}
                        </p>
                      </div>

                      <p className="infoSt-message-time infoSt-p">
                        {getFullTime(time)}
                      </p>
                    </div>
                  </div>
                  <div className="row infoSt-date">
                    <span className="infoSt-span infoSt-choose">
                      {content.title}
                    </span>
                    <div
                      key={`${index + "_btn"}`}
                      style={{
                        display:
                          `${index + "_btn"}` === "0_btn" &&
                          this.state.displayButtons
                            ? "flex"
                            : "none",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <form>
                        {content.mode == "date" ? (
                          <DatePicker
                            autoOk
                            variant="static"
                            openTo={"date"}
                            dateFormat="YYYY-MM-DD"
                            value={this.state.changeDate}
                            {...(this.state.maxDate !== null && {
                              maxDate: this.state.maxDate,
                            })}
                            {...(this.state.minDate !== null && {
                              minDate: this.state.minDate,
                            })}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            onChange={(date) => {
                              this.setState({ changeDate: new Date(date) });
                            }}
                            mode="portrait"
                            style={{ margin: "-1rem auto" }}
                          />
                        ) : content.mode == "time" ? (
                          <TimePicker
                            autoOk
                            variant="static"
                            openTo={"time"}
                            dateFormat="YYYY-MM-DD"
                            value={this.state.changeDate}
                            {...(this.state.maxDate !== null && {
                              maxDate: this.state.maxDate,
                            })}
                            {...(this.state.minDate !== null && {
                              minDate: this.state.minDate,
                            })}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            onChange={(date) => {
                              this.setState({ changeDate: new Date(date) });
                            }}
                            mode="portrait"
                            style={{ margin: "-1rem auto" }}
                          />
                        ) : (
                          <DateTimePicker
                            autoOk
                            variant="static"
                            openTo={"date"}
                            dateFormat="YYYY-MM-DD"
                            value={this.state.changeDate}
                            {...(this.state.maxDate !== null && {
                              maxDate: this.state.maxDate,
                            })}
                            {...(this.state.minDate !== null && {
                              minDate: this.state.minDate,
                            })}
                            mask={(value) =>
                              value
                                ? [
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                    "/",
                                    /\d/,
                                    /\d/,
                                  ]
                                : []
                            }
                            onChange={(date) => {
                              this.setState({ changeDate: new Date(date) });
                            }}
                            mode="portrait"
                            style={{ margin: "-1rem auto" }}
                          />
                        )}

                        {/*  <TimePicker onChange={this.handleTime} value={this.state.time} hintText="Time to be completed by" /> */}
                      </form>
                      <button
                        className="infoSt-buttons infoSt-butt"
                        parent_div={`${index + "_btn"}`}
                        onClick={(e) => {
                          this.sendButtonAnswerofCards({
                            title:
                              content.mode == "date"
                                ? moment(this.state.changeDate).format(
                                    "YYYY-MM-DD"
                                  )
                                : content.mode == "time"
                                ? moment(this.state.changeDate).format(
                                    "hh:mm:ss"
                                  )
                                : moment(this.state.changeDate).format(
                                    "YYYY-MM-DD hh:mm:ss"
                                  ),
                            action:
                              content.mode == "date"
                                ? "setField-" +
                                  moment(this.state.changeDate).format(
                                    "YYYY/MM/DD"
                                  )
                                : content.mode == "time"
                                ? "time-" +
                                  moment(this.state.changeDate).format(
                                    "hh:mm:ss"
                                  )
                                : "datetime-" +
                                  moment(this.state.changeDate).format(
                                    "YYYY-MM-DD hh:mm:ss"
                                  ),
                          });
                        }}
                      >
                        {content.buttonTitle ? content.buttonTitle : "إرسال"}
                      </button>
                      {this.render_Buttons(
                        [
                          {
                            title:
                              this.state.chat_lang == "ar"
                                ? "القائمة الرئيسية"
                                : "Main Menu",
                            action: "مرحبا",
                          },
                        ],
                        index,
                        0
                      )}
                    </div>
                  </div>
                </>
              ) : type == "radio" ? (
                <div className="row infost-radioFlex">
                  <div className="infoSt-radio">
                    {!(`${index + "_btn"}` === "0_btn") ? (
                      <span className="infoSt-span infoSt-choose">
                        {content.title}
                      </span>
                    ) : (
                      ""
                    )}
                    <div
                      key={`${index + "_btn"}`}
                      style={{
                        display:
                          `${index + "_btn"}` === "0_btn" &&
                          this.state.displayButtons
                            ? "block"
                            : "none",
                      }}
                    >
                      <div>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">
                            {content.title}
                          </FormLabel>
                          <RadioGroup
                            defaultValue={
                              this.state.selectedItemTextFromListRadio +
                              "%%%%" +
                              this.state.selectedItemFromListRadio
                            }
                            aria-label="gender"
                            name="gender1"
                            /* value={this.state.Radio} */ onChange={
                              this.selectCategory
                            }
                          >
                            {content.radios
                              ? content.radios.map((object, index) => {
                                  return (
                                    <FormControlLabel
                                      type="radio"
                                      value={
                                        object.title + "%%%%" + object.action
                                      }
                                      control={<Radio />}
                                      label={object.title}
                                    />
                                  );
                                })
                              : ""}
                          </RadioGroup>
                        </FormControl>
                      </div>
                      {content.displayMainButtons && (
                        <div>
                          <button
                            className="infoSt-butlist infoSt-butt"
                            parent_div={`${index + "_btn"}`}
                            onClick={(e) => {
                              this.sendButtonAnswerofCards({
                                title: this.state.selectedItemTextFromListRadio,
                                action: this.state.selectedItemFromListRadio,
                              });
                            }}
                          >
                            {"ارسال"}
                          </button>

                          <div
                            className="infoSt-dis"
                            key={`${index + "_btn"}`}
                            style={{
                              display:
                                `${index + "_btn"}` === "0_btn" &&
                                this.state.displayButtons
                                  ? "flex"
                                  : "none",
                            }}
                          >
                            {this.render_Buttons(content.buttons, index, 0)}
                          </div>
                        </div>
                      )}
                      <div
                        className="infoSt-dis"
                        key={`${index + "_btn"}`}
                        style={{
                          display:
                            `${index + "_btn"}` === "0_btn" &&
                            this.state.displayButtons
                              ? "flex"
                              : "none",
                        }}
                      >
                        {this.render_Buttons(content.buttons, index, 0)}
                      </div>
                    </div>
                  </div>
                </div>
              ) : type == "camera" ? (
                <div className="row infost-radioFlex">
                  <div className="infoSt-radio">
                    {!(`${index + "_btn"}` === "0_btn") ? (
                      <span className="infoSt-span infoSt-choose">
                        {content.message}
                      </span>
                    ) : (
                      ""
                    )}

                    <div>
                      <video
                        id="video-player"
                        width="100%"
                        height="100%"
                      ></video>
                      <canvas
                        id="drawCanvas"
                        class="hide"
                        width="100%"
                        height="100%"
                      ></canvas>
                    </div>
                    {
                      <div>
                        <button
                          id="snapshot"
                          className="infoSt-butlist infoSt-butt"
                          parent_div={`${index + "_btn"}`}
                          /* onClick={(e) => {
                              this.sendButtonAnswerofCards({
                                title: this.state.selectedItemTextFromListRadio,
                                action: this.state.selectedItemFromListRadio,
                              });
                            }} */
                        >
                          {this.state.chat_lang == "ar"
                            ? "التقط وارسل الصورة"
                            : "Take a photo & send"}
                        </button>

                        <div
                          className="infoSt-dis"
                          key={`${index + "_btn"}`}
                          style={{
                            display:
                              `${index + "_btn"}` === "0_btn" &&
                              this.state.displayButtons
                                ? "flex"
                                : "none",
                          }}
                        >
                          {this.render_Buttons(content.buttons, index, 0)}
                        </div>
                      </div>
                    }
                  </div>
                </div>
              ) : type == "list" ? (
                <div className={"infoSt-chat-bot"}>
                  <Avatar className="infoSt-avatar infoSt-avatarbot">
                    <img
                      src={getFileURL(
                        "chatbotinfost/webchat_images/chatLogo.png"
                      )}
                      className="infoSt-avatar-bot"
                    />
                  </Avatar>

                  <div className="row ">
                    <div className="ifoSt-category">
                      <div className="infoSt-message infoSt-ltr-bot-message">
                        <p classN="infoSt-p">
                          {" "}
                          {index + "_btn" === "0_btn" &&
                          this.state.displayButtons
                            ? content.title
                            : content.title
                            ? content.title
                            : content}
                        </p>
                      </div>
                      <div
                        className="infoSt-main"
                        key={`${index + "_btn"}`}
                        style={{
                          display:
                            `${index + "_btn"}` === "0_btn" &&
                            this.state.displayButtons
                              ? "block"
                              : "none",
                        }}
                      >
                        <div className="infoSt-select">
                          <select
                            className={
                              lang == "en"
                                ? "infoSt-ltr-option"
                                : "infoSt-option"
                            }
                            name="slct"
                            id="slct"
                            onChange={this.selectCategory}
                            defaultValue={
                              this.state.selectedItemTextFromListRadio +
                              "%%%%" +
                              this.state.selectedItemFromListRadio
                            }
                          >
                            {content.items
                              ? content.items.map((object, index) => {
                                  return (
                                    <option
                                      className="infoSt-Option"
                                      key={index}
                                      value={
                                        object.title + "%%%%" + object.action
                                      }
                                      type="list"
                                      parent_div={`${index + "_btn"}`}
                                    >
                                      {object.title}
                                    </option>
                                  );
                                })
                              : ""}
                          </select>
                        </div>
                        <button
                          className="infoSt-butlist infoSt-butt"
                          parent_div={`${index + "_btn"}`}
                          onClick={(e) => {
                            this.sendButtonAnswerofCards({
                              title: this.state.selectedItemTextFromListRadio,
                              action: this.state.selectedItemFromListRadio,
                            });
                          }}
                        >
                          {this.state.chat_lang == "ar" ? "اختيار" : "Choose"}
                        </button>
                        {this.render_Buttons(
                          [
                            {
                              title:
                                this.state.chat_lang == "ar"
                                  ? "القائمة الرئيسية"
                                  : "Main Menu",
                              action: "مرحبا",
                            },
                          ],
                          index,
                          0
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : type == "rating" ? (
                <div className="row infoSt-mrgRate">
                  <div className="infoSt-stars">
                    <h5 className="infoSt-h5">{content.message}</h5>
                    {/* <StarRatingComponent
                    name="rate1"
                    starCount={5}
                    value={this.state.rating}
                    onStarClick={this.onStarClick.bind(this)}
                    onStarHover={this.onStarHover.bind(this)}
                    onStarHoverOut={this.onStarHoverOut.bind(this)}
                  /> */}
                    <Box
                      component="fieldset"
                      mb={3}
                      borderColor="transparent"
                      style={{ marginBottom: "0" }}
                    >
                      {/* <Typography component="legend">Custom icon set</Typography> */}
                      <StyledRating
                        name="customized-icons"
                        defaultValue={2}
                        getLabelText={(value) => customIcons[value].label}
                        IconContainerComponent={IconContainer}
                        size="large"
                        max={3}
                        onChange={(event, newValue) => {
                          this.onStarClickClosing(event.target.value);
                        }}
                        onChangeActive={(event, newHover) => {
                          this.onStarHover(newHover);
                        }}
                      />
                    </Box>
                    <h5 className="infoSt-h5" style={{ color: "#947940" }}>
                      {this.state.ratinghover !== -1 && (
                        <Box ml={2}>
                          {this.state.chat_lang == "ar"
                            ? labels[this.state.ratinghover]
                            : labels_en[this.state.ratinghover]}
                        </Box>
                      )}
                    </h5>
                    <div
                      className="infoSt-dis"
                      key={`${index + "_btn"}`}
                      style={{
                        display:
                          `${index + "_btn"}` === "0_btn" &&
                          this.state.displayButtons
                            ? "flex"
                            : "none",
                      }}
                    >
                      {this.render_Buttons(content.buttons, index)}
                    </div>
                  </div>
                </div>
              ) : type == "cards" || type === "nlu cards" ? (
                <div /*key={this.generateKey()}*/ className={"infoSt-chat-bot"}>
                  {
                    <Avatar className="infoSt-avatar infoSt-avatarbot">
                      <img
                        src={getFileURL(
                          "chatbotinfost/webchat_images/chatLogo.png"
                        )}
                        className="infoSt-avatar-bot"
                      />
                    </Avatar>
                  }

                  {(content.cards &&
                    this.state.displayButtons &&
                    content.cards.length > 0 &&
                    index == 0) ||
                  sequence == -1 ? (
                    <Slider
                      text={content.message}
                      Cards={content.cards}
                      index={index}
                      lang={lang}
                      displayButtons={this.state.displayButtons}
                      sendButtonAnswerofCards={(title, value) =>
                        this.sendButtonAnswerofCards(title, value)
                      }
                      getStyleFromString={(style) =>
                        this.getStyleFromString(style)
                      }
                      generateKey={this.generateKey}
                    />
                  ) : (
                    <div
                      key={index}
                      className={
                        "infoSt-message " +
                        (lang == "en"
                          ? "infoSt-ltr-bot-message"
                          : "infoSt-bot-message")
                      }
                    >
                      {" "}
                      <div
                        className={
                          lang == "en"
                            ? "infoSt-just-line-break-ltr"
                            : "infoSt-just-line-break"
                        }
                      >
                        <p className="infoSt-p">
                          {type === "nlu cards" &&
                          content.cards?.length &&
                          index !== 0
                            ? content.cards[0].title
                            : typeof content === "string" &&
                              content !== "cards view"
                            ? content
                            : lang == "ar"
                            ? "   كيف أساعدك ؟"
                            : " ? How can I help you "}
                        </p>
                      </div>
                      <p className="infoSt-message-time infoSt-p">
                        {getFullTime(time)}
                      </p>
                    </div>
                  )}
                </div>
              ) : type == "matrix" ? (
                <div className={"infoSt-chat-bot"}>
                  {
                    <Avatar className="infoSt-avatar infoSt-avatarbot">
                      <img
                        src={getFileURL(
                          "chatbotinfost/webchat_images/chatLogo.png"
                        )}
                        className="infoSt-avatar-bot"
                      />
                    </Avatar>
                  }

                  {(content.elemnts &&
                    this.state.displayButtons &&
                    content.elemnts.length > 0 &&
                    index == 0) ||
                  sequence == -1 ? (
                    <Slider
                      text={content.message}
                      Matrix={this.getMatrix(content.elemnts)}
                      index={index}
                      lang={lang}
                      displayButtons={this.state.displayButtons}
                      sendButtonAnswerofCards={(title, value) =>
                        this.sendButtonAnswerofCards(title, value)
                      }
                      getStyleFromString={(style) =>
                        this.getStyleFromString(style)
                      }
                      generateKey={this.generateKey}
                    />
                  ) : (
                    <div
                      key={index}
                      className={
                        "infoSt-message " +
                        (lang == "en"
                          ? "infoSt-ltr-bot-message"
                          : "infoSt-bot-message")
                      }
                    >
                      {" "}
                      <div
                        className={
                          lang == "en"
                            ? "infoSt-just-line-break-ltr"
                            : "infoSt-just-line-break"
                        }
                      >
                        <p className="infoSt-p">
                          {type === "matrix"
                            ? content.message
                              ? Htmlparser(urlify(content.message, lang))
                              : Htmlparser(urlify(content, lang))
                            : lang == "ar"
                            ? "   كيف أساعدك ؟"
                            : " ? How can I help you "}
                        </p>
                      </div>
                      <p className="infoSt-message-time infoSt-p">
                        {getFullTime(time)}
                      </p>
                    </div>
                  )}
                </div>
              ) : type == "webview" ? (
                content.mode == "iframe" ? (
                  <div
                    class=" infoSt-agent
                  infoSt-mrht  "
                  >
                    <div
                      class=" infoSt-mrht"
                      style={{
                        display:
                          `${index + "_btn"}` === "0_btn" &&
                          this.state.displayButtons
                            ? "flex"
                            : "none",
                        background: `url(${getFileURL(
                          "chatbotinfost/webchat_images/pageloader11.gif"
                        )}) center center no-repeat`,
                      }}
                    >
                      {
                        <iframe
                          id="myiframe"
                          ref="myiframe"
                          src={content.message}
                          //src={"https://app.purechat.com/VisitorWidget/ChatWindow?widgetId=fbe3c090-f58c-44a2-aa7e-0f574880a8bc"}
                          frameborder="0"
                          border="0"
                          cellspacing="0"
                          border-style="none"
                          width="330"
                          height="430"
                        >
                          {" "}
                        </iframe>
                      }
                      `
                    </div>
                    <div
                      className="infoSt-dis"
                      key={`${index + "_btn"}`}
                      style={{
                        display:
                          `${index + "_btn"}` === "0_btn" &&
                          this.state.displayButtons
                            ? "flex"
                            : "none",
                      }}
                    >
                      {this.render_Buttons(content.buttons, index)}
                    </div>
                  </div>
                ) : content.mode == "html" ? (
                  <div class="row infoSt-mrht">
                    <div class="infoSt-ul">
                      {
                        <Avatar className="infoSt-avatar infoSt-avatarbot">
                          <img
                            src={getFileURL(
                              "chatbotinfost/webchat_images/chatLogo.png"
                            )}
                            className="infoSt-avatar-bot"
                          />
                        </Avatar>
                      }

                      {Htmlparser(urlify(content.message, lang))}
                      <div
                        className="infoSt-dis"
                        key={`${index + "_btn"}`}
                        style={{
                          display:
                            `${index + "_btn"}` === "0_btn" &&
                            this.state.displayButtons
                              ? "flex"
                              : "none",
                        }}
                      >
                        {this.render_Buttons(content.buttons, index)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="row infoSt-mrht"></div>
                )
              ) : (
                <div>
                  <div className={"infoSt-chat-bot"}>
                    {
                      <Avatar className="infoSt-avatar infoSt-avatarbot">
                        <img
                          src={getFileURL(
                            "chatbotinfost/webchat_images/chatLogo.png"
                          )}
                          className="infoSt-avatar-bot"
                        />
                      </Avatar>
                    }

                    <div
                      key={index}
                      className={`${
                        type == ".doc" || type == ".pdf" || type == "file"
                          ? "infoSt-message-icon"
                          : "infoSt-message"
                      }
            ${lang == "en" ? "infoSt-ltr-bot-message" : "infost-bot-message"}`}
                    >
                      {type == ".png" ||
                      type == ".gif" ||
                      type == ".jpg" ||
                      type == "image" ? (
                        <a
                          href={content.url ? content.url : content}
                          target="_blank"
                        >
                          <img src={content.url ? content.url : content}></img>
                        </a>
                      ) : type == ".doc" || type == ".pdf" || type == "file" ? (
                        <a
                          href={content.url ? content.url : content}
                          target="_blank"
                        >
                          <img
                            src={
                              config.network.socketHostPublic +
                              "/webchat_images/pdffile.png"
                            }
                            width="50px"
                            height="50px"
                          ></img>
                        </a>
                      ) : type == "control-minimizeWindow" ? (
                        this.props.onMinimize()
                      ) : (
                        <div
                          className={
                            lang == "en"
                              ? "infoSt-just-line-break-ltr"
                              : "infoSt-just-line-break"
                          }
                        >
                          {content && content.message
                            ? Htmlparser(urlify(content.message, lang))
                            : !content || content == ""
                            ? "..."
                            : Htmlparser(urlify(content, lang))}
                        </div>
                      )}
                      <p className="infoSt-message-time infoSt-p">
                        {getFullTime(time)}
                      </p>
                    </div>
                  </div>
                </div>
              )
            ) : (
              ""
            )
        )
      )}
    </div>
  );

  getStyleFromString(StyleStr) {
    try {
      var StyleStrResult = JSON.parse(StyleStr);
    } catch (e) {
      return {};
    }
    return StyleStrResult;
  }

  render() {
    const stylehideclassicinput = { display: "none" };
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="row">
          <div
            className={
              this.state.chatbotlang == "ar"
                ? "infoSt-container"
                : "infoSt-container-right"
            }
          >
            {/* <div className="infoSt-backline">
              <img
                src={getFileURL("chatbotinfost/webchat_images/Ad31.png")}
                className="infoSt-backav"
              />
            </div> */}
            <div className="infoSt-header">
              <div />

              <div
                className="infoSt-minimize"
                onClick={() => {
                  this.props.onMinimize();
                }}
              >
                <img src={getFileURL("chatbotinfost/webchat_images/min.png")} />
              </div>
              <div
                className="infoSt-close1"
                onClick={() => {
                  this.state.messages.length > 0
                    ? this.sendExitWindow()
                    : this.props.onClose();
                }}
              >
                <img src={getFileURL("chatbotinfost/webchat_images/x.png")} />
              </div>

              <div className="infoStchat">
                <img
                  src={getFileURL("chatbotinfost/webchat_images/chatLogo.png")}
                  className="infoSt-ad"
                />
              </div>
            </div>
            <div
              id="infoSt-content"
              className={
                this.state.messages.length <= 2 &&
                this.state.messages.length != 0
                  ? "infoSt-content-onetime"
                  : "infoSt-content"
              }
            >
              <div ref={this.myRef} />
              <div className="infoSt-openlist">
                {(() => {
                  if (this.state.error) {
                    return this.renderNetworkError();
                  }
                  if (this.state.loading) {
                    return this.renderSpinner();
                  }
                  if (this.state.loadingMessages) {
                    return this.renderSpinner();
                  }

                  if (this.state.messages.length === 0) {
                    return this.renderNoContent();
                  }
                  return this.renderMessages();
                })()}
              </div>
            </div>

            <div
              dir={this.state.chatbotlang == "ar" ? "ltr" : "rtl"}
              className="infoSt-input"
              style={{
                display: (this.state.hideInfoStInput && "none") || true,
              }}
            >
              <input
                type={this.state.isLastMessagePassword ? "password" : "text"}
                // disabled={this.state.questionTextDisabled}
                disabled={this.state.questionTextDisabled}
                className="infoSt-input-field infoSt-inp"
                value={this.state.inputValue}
                onChange={this.onChange}
                onKeyDown={this.onKeyDown}
                placeholder={
                  this.state.chat_lang == "ar" || this.state.chatbotlang == "ar"
                    ? "...اكتب رسالة"
                    : "Type your message..."
                }
                ref={(input) => {
                  this.newMessage = input;
                }}
              />

              <button
                onClick={this.onSend}
                className="infoSt-sendbtn infoSt-butt"
              >
                <img
                  src={getFileURL(
                    `chatbotinfost/webchat_images/${
                      this.state.chatbotlang == "ar"
                        ? "icons-02.png"
                        : "icons-03.png"
                    }`
                  )}
                  className="infoSt-arr"
                />
              </button>
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    );
  }
}

export default Chat;

const getIntroMessageData = (lang) => {
  let chatbotName, organization, description, photoUrl;

  photoUrl = "chatLogo.png";

  if (lang == "ar") {
    chatbotName = "فاروق";
    organization = "مركز الشارقة للتحكيم التجاري الدولي";
    description = `أهلاً بك في نظام المجيب الذكي ${chatbotName}، يسعدني مساعدتك والإجابة عن أسئلتك حول  ${organization}.`;
  } else {
    chatbotName = "Farouk";
    organization = "Sharjah International Commercial Arbitration Centre ";
    description = `Welcome to Our Virtual Assistant ${chatbotName}, I am here to help answer your questions about  ${organization}`;
  }

  return { description, photoUrl };
};
