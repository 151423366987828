import React, { Component } from "react";
import Badge from "./Badge";
import Chat from "./Chat";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMinimized: false,
      token: this.props.token ? this.props.token : 0,
      opened: false,
      lang: this.props.lang ? this.props.lang : "en",
      chatbotlang:
        !this.props.chatbotlang || typeof this.props.chatbotlang == "undefined"
          ? "en"
          : this.props.chatbotlang,
      value: "",
      username: "",
      password: "",
      error: "",
      has_user_token: false,
      interval: 0,
    };
    this.handlePassChange = this.handlePassChange.bind(this);
    this.handleUserChange = this.handleUserChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.dismissError = this.dismissError.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.resetInterval = this.resetInterval.bind(this);
    localStorage.removeItem("chatbotsystem_user_token");
    var lang_stored = window.localStorage.getItem("chatbotlang");
    if (!lang_stored || typeof lang_stored == "undefined") {
      window.localStorage.setItem("chatbotlang", this.state.chatbotlang);
      lang_stored = this.state.chatbotlang;
    }

    setInterval(() => {
      let oldlang = lang_stored || this.state.chatbotlang;
      let newlang =
        !window.chatbotlang || typeof window.chatbotlang == "undefined"
          ? "en"
          : window.chatbotlang;
      if (oldlang != newlang) {
        window.localStorage.setItem("chatbotlang", newlang);
        this.setState({ chatbotlang: newlang, interval: 1 });
        lang_stored = newlang;
      }
    }, 2000);
  }
  dismissError() {
    this.setState({ error: "" });
  }

  resetInterval() {
    this.setState({
      interval: 0,
    });
  }

  handleSubmit(evt) {
    evt.preventDefault();

    // if (!this.state.username) {
    //   return this.setState({ error: 'Username is required' });
    // }

    // if (!this.state.password) {
    //   return this.setState({ error: 'Password is required' });
    // }

    // return this.setState({ error: '' });

    localStorage.setItem("chatbotsystem_user_token", new Date().getTime());
    this.setState({ has_user_token: true });
  }
  handleSignOut(evt) {
    evt.preventDefault();
    localStorage.removeItem("chatbotsystem_user_token");
    this.setState({
      username: "",
      password: "",
      error: "",
      has_user_token: false,
    });
  }

  handleUserChange(evt) {
    this.setState({
      username: evt.target.value,
    });
  }

  handlePassChange(evt) {
    this.setState({
      password: evt.target.value,
    });
  }

  onOpen = () => {
    this.setState({ opened: true });
  };
  onClose = () => {
    this.setState({ isMinimized: false, opened: false });
  };
  onMinimize = () => {
    this.setState({ isMinimized: true, opened: false });
  };

  render() {
    return (
      <div>
        {/* {!this.state.has_user_token && <div className="InfoSt_Login">
          <form onSubmit={this.handleSubmit}>
            {
              this.state.error &&
              <h3 data-test="error" onClick={this.dismissError}>
                <button onClick={this.dismissError}>✖</button>
                {this.state.error}
              </h3>
            }
            <label>User Name</label>
            <input type="text" data-test="username" value={this.state.username} onChange={this.handleUserChange} />

            <label>Password</label>
            <input type="password" data-test="password" value={this.state.password} onChange={this.handlePassChange} />

            <input type="submit" value="Log In" data-test="submit" />
          </form>
        </div>}
        {
          this.state.has_user_token && <form onSubmit={this.handleSignOut}>
            <input type="submit" value="Sign out" data-test="submit" />
          </form>
        } */}
        {!this.state.opened && (
          <Badge
            key="1"
            chatbotlang={this.state.chatbotlang}
            onClick={() => {
              this.onOpen();
            }}
          />
        )}
        {this.state.opened && (
          <Chat
            onClose={this.onClose}
            onMinimize={this.onMinimize}
            resetInterval={this.resetInterval}
            chatbotlang={this.state.chatbotlang}
            interval={this.state.interval}
            token={this.state.token}
            isMinimized={this.state.isMinimized}
          />
        )}
      </div>
    );
  }
}
export default App;
