import React, { Component } from "react";
import config from "./../config";
class LeftArrow extends Component {
  render() {
    return (
      <div
        onClick={this.props.goToPrevSlide}
        className="infoSt-arrow"
        tabindex="0"
        data-toggle="tooltip"
        title="التالي "
      >
        <img
          src={`${config.cssJsHostPublic}/chatbotinfost/webchat_images/g1.png`}
          className="infoSt-arro"
        />
      </div>
    );
  }
}

export default LeftArrow;
