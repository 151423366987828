import React, { Component } from "react";
import LeftArrow from "./left_arrow";
import RightArrow from "./right_arrow";
import "./../Chat.css";
import CustomizedExpansionPanel from "./CustomizedExpansionPanel";
import Htmlparser from "html-react-parser";
import { urlify } from "../strings";

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
      Cards: this.props.Cards ? this.props.Cards : [],
      Matrix: this.props.Matrix ? this.props.Matrix : [],
      length: this.props.Cards
        ? this.props.Cards.length
        : this.props.Matrix
        ? this.props.Matrix.length
        : 0,
    };
  }

  goToPrevSlide() {
    let index = this.state.activeIndex;
    let length = this.state.length;

    if (index < 1) {
      index = length - 1;
    } else {
      index--;
    }

    this.setState({
      activeIndex: index,
    });
  }

  goToNextSlide() {
    let index = this.state.activeIndex;
    let length = this.state.length;

    if (index === length - 1) {
      index = 0;
    } else {
      index++;
    }

    this.setState({
      activeIndex: index,
    });
  }

  render_Buttons = (buttons) =>
    buttons
      ? buttons.map((button) => {
          return (
            <button
              value={button.action}
              className="infost-btnCaro1  infost-expand infoSt-butt"
              type="button"
              disabled={button.disabled ? true : false}
              onClick={() => {
                this.props.sendButtonAnswerofCards(button);
              }}
              style={this.props.getStyleFromString(button.style)}
            >
              <span className="infoSt-span"> {button.title}</span>
            </button>
          );
        })
      : "";

  render() {
    return (
      <div className="infoSt-slider" key={`${this.props.index + "_slider"}`}>
        <div className="infoSt-slider-items">
          {this.state.length > 1 ? (
            <div
              className="  infoSt-left"
              style={{
                display:
                  `${this.props.index + "_slider"}` === "0_slider" &&
                  this.props.displayButtons
                    ? "flex"
                    : "none",
              }}
            >
              <LeftArrow goToPrevSlide={() => this.goToPrevSlide()} />
            </div>
          ) : (
            ""
          )}
          <section>
            {this.state.Cards.map((card, index) => (
              <div
                className={
                  index === this.state.activeIndex
                    ? "infoSt-active"
                    : "infoSt-slide"
                }
                key={index}
              >
                <div className="infostCarousel-inner " role="listbox">
                  <div className="infostCarousel-item infoStcarousel-item">
                    {this.props.text && (
                      <div
                        className={
                          this.props.lang == "en"
                            ? "infoSt-ltr-bot-message" + " infoSt-message-ltr"
                            : "infoSt-bot-message" + " infoSt-message"
                        }
                      >
                        <div
                          className={
                            this.props.lang == "en"
                              ? "infoSt-just-line-break-ltr"
                              : "infoSt-just-line-break"
                          }
                        >
                          <p className="infoSt-p">
                            {Htmlparser(
                              urlify(this.props.text, this.props.lang)
                            )}
                          </p>
                        </div>
                      </div>
                    )}
                    {card.photoUrl && card.photoUrl != "" ? (
                      <div className="infoSt-logo">
                        <div className="infost-imgslide">
                          <img
                            src={card.photoUrl}
                            onError={(i) => (i.target.style.display = "none")}
                            alt="First slide"
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    <div
                      className={`${
                        this.state.length === 1
                          ? "infostCarousel-caption-center"
                          : "infostCarousel-caption"
                      }`}
                    >
                      <h3
                        className={
                          this.props.lang == "en"
                            ? "infost-ltr-mr"
                            : "infost-mr"
                        }
                      >
                        {card.title
                          ? Htmlparser(urlify(card.title, this.props.lang))
                          : card.title}
                      </h3>
                      <p
                        className={
                          this.props.lang == "en"
                            ? "infoSt-ltr-mt-1"
                            : "infoSt-mt-1"
                        }
                      >
                        {card.description
                          ? Htmlparser(
                              urlify(card.description, this.props.lang)
                            )
                          : card.description}
                      </p>
                    </div>
                    {!card.ExpansionPanel_first ? (
                      <div
                        className="infoSt-btnCaro"
                        style={{
                          display:
                            `${this.props.index + "_slider"}` === "0_slider" &&
                            this.props.displayButtons
                              ? "flex"
                              : "none",
                        }}
                      >
                        {this.render_Buttons(card.buttons)}
                        <CustomizedExpansionPanel
                          Panels={
                            card.expansionPanels &&
                            card.expansionPanels.length > 0
                              ? card.expansionPanels
                              : []
                          }
                          isActive={index === this.state.activeIndex}
                          lang={this.props.lang}
                        />
                      </div>
                    ) : (
                      <div
                        className="infoSt-btnCaro"
                        style={{
                          display:
                            `${this.props.index + "_slider"}` === "0_slider" &&
                            this.props.displayButtons
                              ? "flex"
                              : "none",
                        }}
                      >
                        <CustomizedExpansionPanel
                          key={this.props.generateKey}
                          generateKey={this.props.generateKey}
                          Panels={
                            card.expansionPanels &&
                            card.expansionPanels.length > 0
                              ? card.expansionPanels
                              : []
                          }
                          isActive={index === this.state.activeIndex}
                          lang={this.props.lang}
                        />
                        {this.render_Buttons(card.buttons)}
                      </div>
                    )}

                    {card.buttonsExpansionPanels &&
                    card.buttonsExpansionPanels.length > 0
                      ? card.buttonsExpansionPanels.map((button) => {
                          if (button.type == "Button")
                            return (
                              <div>
                                <button
                                  value={button.action}
                                  className="infost-btnCaro1  infost-expand infoSt-butt"
                                  type="button"
                                  disabled={button.disabled ? true : false}
                                  onClick={() => {
                                    if (button.URL)
                                      window.open(button.URL, "_blank");
                                    else
                                      this.props.sendButtonAnswerofCards(
                                        button
                                      );
                                  }}
                                  style={this.props.getStyleFromString(
                                    button.style
                                  )}
                                >
                                  <span className="infoSt-span">
                                    {" "}
                                    {button.title}
                                  </span>
                                </button>
                              </div>
                            );
                          else if (button.type == "ExpansionPanel")
                            return (
                              <CustomizedExpansionPanel
                                Panels={[button]}
                                isActive={index === this.state.activeIndex}
                                lang={this.props.lang}
                              />
                            );
                        })
                      : ""}
                  </div>{" "}
                </div>
              </div>
            ))}
            {this.state.Matrix.map((arr2d, index) => {
              return (
                <div
                  className={
                    index === this.state.activeIndex
                      ? "infoSt-active"
                      : "infoSt-slide"
                  }
                  key={index}
                >
                  <div className="infostCarousel-inner " role="listbox">
                    <div className="infostCarousel-item infoStcarousel-item">
                      {this.props.text && (
                        <div
                          className={
                            this.props.lang == "en"
                              ? "infoSt-ltr-bot-message" + " infoSt-message-ltr"
                              : "infoSt-bot-message" + " infoSt-message"
                          }
                        >
                          <div
                            className={
                              this.props.lang == "en"
                                ? "infoSt-just-line-break-ltr"
                                : "infoSt-just-line-break"
                            }
                          >
                            <p className="infoSt-p">
                              {Htmlparser(
                                urlify(this.props.text, this.props.lang)
                              )}
                            </p>
                          </div>
                        </div>
                      )}
                      <div>
                        {arr2d.map((arr1d) => {
                          return (
                            <div className="infoSt-single-btn-row">
                              {arr1d.map((el) => {
                                const { title, action, type } = el;
                                return (
                                  <div>
                                    <button
                                      value={action}
                                      className="infost-btnCaro1  infost-expand infoSt-matrix-butt"
                                      type="button"
                                      disabled={el.disabled ? true : false}
                                      onClick={() => {
                                        this.props.sendButtonAnswerofCards(el);
                                      }}
                                      style={this.props.getStyleFromString(
                                        el.style
                                      )}
                                    >
                                      <span className="infoSt-span">
                                        {title}
                                      </span>
                                    </button>
                                  </div>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </section>
          {this.state.length > 1 ? (
            <div
              className="infoSt-right"
              style={{
                display:
                  `${this.props.index + "_slider"}` === "0_slider" &&
                  this.props.displayButtons
                    ? "flex"
                    : "none",
              }}
            >
              <RightArrow goToNextSlide={() => this.goToNextSlide()} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default Slider;

const buttonsExpansionPanels = [
  { title: "Button", type: "Button", action: "1" },
  {
    title: "ExpansionPanel",
    type: "ExpansionPanel",
    description: "description",
  },
  { title: "Button", type: "Button", action: "1" },
  {
    title: "ExpansionPanel",
    type: "ExpansionPanel",
    description: "description",
  },
  {
    title: "ExpansionPanel",
    type: "ExpansionPanel",
    description: "description",
  },
  { title: "Button", type: "Button", action: "1" },
  {
    title: "ExpansionPanel",
    type: "ExpansionPanel",
    description: "description",
  },
  { title: "Button", type: "Button", action: "1" },
  { title: "Button", type: "Button", action: "1" },
  {
    title: "ExpansionPanel",
    type: "ExpansionPanel",
    description: "description",
  },
  { title: "Button", type: "Button", action: "1" },
  {
    title: "ExpansionPanel",
    type: "ExpansionPanel",
    description: "description",
  },
];
